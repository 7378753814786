/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/LockOutlined';

// core components
import { startGoogleLogin } from '../actions/auth';
import MenuContext from '../context/menu-context';

// VISUAL COMPONENTS
// import CustomButton from '../components/CustomButtons/Button';
import Card from '../components/card/Card';
import CardBody from '../components/card/CardBody';
import CardHeader from '../components/card/CardHeader';
import CardFooter from '../components/card/CardFooter';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import CustomInput from '../components/CustomInput/CustomInput';

import {
  cardTitle,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from '../assets/jss/material-dashboard-react';

const styles = theme => ({
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '70px',
    '@media (min-width: 768px)': {
      width: '750px',
    },
    '@media (min-width: 992px)': {
      width: '970px',
    },
    '@media (min-width: 1200px)': {
      width: '1170px',
    },
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    color: 'blue',
    'background-color': 'white',
    'border-color': 'white',
  },
  ordinaryButtonClass: {
    margin: theme.spacing(1),
    color: 'black',
    // 'background-color': grayColor[14],
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  formControlClassName: {
    margin: '0',
    paddingBottom: '0',
    '& + $formControlClassName': {
      marginTop: '5px',
    },
  },
  checkboxLabelControlClassName: {
    marginTop: '16px',
  },
  checkboxLabel: {
    color: `rgba(${hexToRgb(blackColor)}, 0.26)`,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});


const LoginPage = (props) => {
  const { classes } = props;
  const { firebaseHook } = useContext(MenuContext);
  const [errors] = useState({
    username: false,
    invalidEmailOrPassword: false,
  });

  const onGoogleLogin = () => {
    props.startGoogleLogin(firebaseHook.app);
  };

  const doLogin = async (e) => {
    e.preventDefault();

    // const { history } = this.props;

    const fields = ['email', 'password'];
    const formElements = e.target.elements;

    const formValues = fields
      .map(field => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    firebaseHook.app.auth().signInWithEmailAndPassword(formValues.email, formValues.password)
      .catch(
        (error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`${errorCode}${errorMessage}`);
          // ...
        },
      );
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={doLogin}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log in with 3rd party</h4>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.ordinaryButtonClass}
                  onClick={() => { onGoogleLogin(); }}
                >
                  <img
                    className={clsx(classes.leftIcon, classes.iconSmall)}
                    alt="..."
                    src={require('../images/icons/common/google.svg')}
                  />
                  <span>Google</span>
                </Button>
              </CardHeader>
              <CardBody>
                <p
                  className={`${classes.textCenter}`}
                >
                  Or Sign in with credentials
                </p>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  error={errors.username || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    required: true,
                    name: 'email',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  error={errors.password || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: 'password',
                    required: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" variant="contained" color="secondary">
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  //   history: PropTypes.object,
  //   errors: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  startGoogleLogin: auth => dispatch(startGoogleLogin(auth)),
});

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(LoginPage));
